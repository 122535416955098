(function () {
    'use strict';

    /**
     * AlphaAutoExpandInput is allow you add text and text box will automatically grow on enter or end of line
     *
     * @method alphaAutoExpandInput
     *
     * @example
     *      HTML:
     *      <div alpha-auto-expand-input
     *          class='input-field form-control'
     *          >
     *          <textarea
     *          id="some_id"
     *           ng-model="modelVar"
     *           ng-model-update-onblur
     *           read-only-mode="true"
     *          ></textarea>
     *      </div>
     *
     * @param {Object} ng-model Model to bind this widget to .
     * @param {Boolean} [read-only-mode] Makes the input permanently read only even if a rule makes it writable.
     */
    angular
        .module('alpha.common.directives.alphaAutoExpandInput', [
            'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.ruleExecutionHelper'
        ])
        .directive('alphaAutoExpandInput', alphaAutoExpandInput);

    alphaAutoExpandInput.$inject = ['RequiredFieldService', 'RuleExecutionHelper'];

    function alphaAutoExpandInput(RequiredFieldService, RuleExecutionHelper) {
        var readOnlyClass = 'readonly';
        return {
            restrict: 'A',
            transclude: true,
            replace:false,
            template: '<div class="alpha-autoexpand-textarea-container"><ng-transclude></ng-transclude><div class="alpha-autoexpand-textarea-size"></div></div> ',
            link: link
        };

        function link(scope, element, attrs) {
            var textareaSize, input, modelCtrl, _readOnlyMode, _readOnlyState;

            textareaSize = element.find('.alpha-autoexpand-textarea-size');
            input = element.find('textarea');

            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setReadOnly', 'setRequired'], attrs, _eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
            });
            function _eventHandler(data, event) {
                modelCtrl=input.controller('ngModel');
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        break;
                    case 'setRequired':
                        _setRequired();
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                        _disableRequired();
                    }
                }
            }
            function _enableReadOnly() {
                element.find('textarea').addClass(readOnlyClass).prop('readonly', true).prop('disabled', true);
                element.addClass(readOnlyClass).prop('readonly', true).prop('disabled', true);
            }
            function _disableReadOnly() {
                element.find('textarea').removeClass(readOnlyClass).prop('readonly', false).prop('disabled', false);
                element.removeClass(readOnlyClass).prop('readonly', false).prop('disabled', false);
            }
            function _enableRequired() {
                modelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                modelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(modelCtrl.$modelValue));
            }
            function _disableRequired() {
                modelCtrl.$setValidity('required', true);
                delete modelCtrl.$validators.required;
            }



            input.on("input", function () {
                autoSize(input.val());
            });

            scope.$watch(input.attr('ng-model'), function(val) {
                autoSize(val);
            });

            function autoSize(val) {
                textareaSize.html(_.escape(val) + '\n');
            }
        }
    }

})();
